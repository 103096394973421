import { AppEnvironment, SwitchFromEnum } from '@nf/types/site';
import { matchEquals, matchSome } from '@nf/utils-common/fpw/monadic';
import { extractOneDomain, splitHostNameWithFirstDot } from '@nf/utils-common/url';

type OneDomainForApiCriteria = {
    randomId: string;
    webFunctionId: string;
    accountStatus: number;
	uatEnvironment: string;
	enableGalaxyAwsRequestToOnPremise: boolean;
}

type OneDomainForGoBackCritria = {
	randomId: string;
	webFunctionId: string;
	switchFrom: SwitchFromEnum;
	uatEnvironment: string;
	enableDesktopOrMobileToOnPremise: boolean;
}

const getOneDomainForApi = (oneDomainForWeb: string, accountStatus: number, enableGalaxyAwsRequestToOnPremise: boolean): string => {
	const [randomId, webFunctionId, uatEnvironment] = extractOneDomain(oneDomainForWeb);

	const criteria: OneDomainForApiCriteria = {
		randomId,
		webFunctionId,
		accountStatus,
		uatEnvironment,
		enableGalaxyAwsRequestToOnPremise
	};

	const oneDomainForApi: string = matchSome(criteria)
	// 地端prod且未登入走mi, 雲端若enableGalaxyAwsRequestToOnPremise=true 走地端mobile api
		.on(({ webFunctionId, accountStatus, enableGalaxyAwsRequestToOnPremise }: OneDomainForApiCriteria) => accountStatus == 0 && (['gr', 'gp'].indexOf(webFunctionId) != -1 || (['gc', 'go'].indexOf(webFunctionId) != -1 && enableGalaxyAwsRequestToOnPremise)),
			({ randomId }: OneDomainForApiCriteria) => `${randomId}mi`)
	// 地端prod且存款走ma, 雲端若enableGalaxyAwsRequestToOnPremise=true 走地端mobile api
		.on(({ webFunctionId, accountStatus, enableGalaxyAwsRequestToOnPremise }: OneDomainForApiCriteria) => accountStatus == 2 && (['gr', 'gp'].indexOf(webFunctionId) != -1 || (['gc', 'go'].indexOf(webFunctionId) != -1 && enableGalaxyAwsRequestToOnPremise)),
			({ randomId }: OneDomainForApiCriteria) => `${randomId}ma`)
	// 地端prod且未存款或其他狀態走mb, 雲端若enableGalaxyAwsRequestToOnPremise=true 走地端mobile api
		.on(({ webFunctionId, enableGalaxyAwsRequestToOnPremise }: OneDomainForApiCriteria) => ['gr', 'gp'].indexOf(webFunctionId) != -1 || (['gc', 'go'].indexOf(webFunctionId) != -1 && enableGalaxyAwsRequestToOnPremise),
			({ randomId }: OneDomainForApiCriteria) => `${randomId}mb`)
	// 雲端prod TW未登入和未存款走mc, enableGalaxyAwsRequestToOnPremise=false 走雲端mobile api
		.on(({ webFunctionId, enableGalaxyAwsRequestToOnPremise }: OneDomainForApiCriteria) => ['gc', 'go'].indexOf(webFunctionId) != -1 && !enableGalaxyAwsRequestToOnPremise,
			({ randomId }: OneDomainForApiCriteria) => `${randomId}mc`)
	// 雲端prod kl走mk
		.on(({ webFunctionId }: OneDomainForApiCriteria) => ['gk', 'gl'].indexOf(webFunctionId) != -1,
			({ randomId }: OneDomainForApiCriteria) => `${randomId}mk`)
	//地端UAT 與Mobile 1:1
		.on(({ webFunctionId, enableGalaxyAwsRequestToOnPremise }: OneDomainForApiCriteria) => webFunctionId == 'gu' || (webFunctionId == 'gd' && enableGalaxyAwsRequestToOnPremise),
			() => `${randomId}ul${uatEnvironment}`)
	//雲端UAT寫死
		.on(({ webFunctionId, enableGalaxyAwsRequestToOnPremise }: OneDomainForApiCriteria) => webFunctionId == 'gd' && !enableGalaxyAwsRequestToOnPremise,
			() => `${randomId}om${uatEnvironment}`)
	// 地端sbtest走tm
		.on(({ webFunctionId }: OneDomainForApiCriteria) => webFunctionId == 'gt',
			({ randomId }: OneDomainForApiCriteria) => `${randomId}tm`)
	//地端SIT 與Mobile 1:1
		.on(({ webFunctionId }: OneDomainForApiCriteria) => webFunctionId == 'gs',
			() => `${randomId}ms${uatEnvironment}`)
	//其他poc先暫時寫死
		.otherwise(() => 'k957um419');

	return oneDomainForApi;
}


const getOneDomainLoginUrl = (switchFrom: SwitchFromEnum, sourceUrl: string, enableDesktopOrMobileToOnPremise: boolean, hasToken: boolean) => {
	const url = new URL(sourceUrl);
	if (url.hostname.includes('localhost')) return sourceUrl;
	const [subDomain, mainDomain] = splitHostNameWithFirstDot(url.host);
	const [randomId, webFunctionId, uatEnvironment] = extractOneDomain(subDomain);
	const criteria: OneDomainForGoBackCritria = {
		webFunctionId,
		switchFrom,
		randomId,
		uatEnvironment,
		enableDesktopOrMobileToOnPremise
	};

	const convertedSubdomain = matchSome(criteria)
		/** production local to desktop */
		.on(({ switchFrom, enableDesktopOrMobileToOnPremise }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Desktop && (webFunctionId == 'gr' ||  (webFunctionId == 'gc' && enableDesktopOrMobileToOnPremise)),
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}ob`)
		/** production local to mobile */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Mobile && (webFunctionId == 'gr' || (webFunctionId == 'gc' && enableDesktopOrMobileToOnPremise)),
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}mi`)
		/** production aws to desktop */
		.on(({ switchFrom, enableDesktopOrMobileToOnPremise }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Desktop && webFunctionId == 'gc' && !enableDesktopOrMobileToOnPremise,
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}xb`)
		/** production aws to mobile */
		.on(({ switchFrom, enableDesktopOrMobileToOnPremise }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Mobile && webFunctionId == 'gc' && !enableDesktopOrMobileToOnPremise,
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}mc`)
		/** production kl to desktop */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Desktop && webFunctionId == 'gk',
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}kb`)
		/** production kl to mobile */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Mobile && webFunctionId == 'gk',
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}mk`)
		/** sbtest to desktop */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Desktop && webFunctionId == 'gt',
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}to`)
		/** sbtest to mobile */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Mobile && webFunctionId == 'gt',
			({ randomId }: OneDomainForGoBackCritria) => `${randomId}tm`)
		/** uat local to desktop */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Desktop && (webFunctionId == 'gu' || (webFunctionId == 'gd' && enableDesktopOrMobileToOnPremise)),
			({ randomId, uatEnvironment }: OneDomainForGoBackCritria) => `${randomId}uo${uatEnvironment}`)
		/** uat local to mobile */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Mobile && (webFunctionId == 'gu' || (webFunctionId == 'gd' && enableDesktopOrMobileToOnPremise)),
			({ randomId, uatEnvironment }: OneDomainForGoBackCritria) => `${randomId}ul${uatEnvironment}`)
		/** uat aws to desktop */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Desktop && webFunctionId == 'gd' && !enableDesktopOrMobileToOnPremise,
			({ randomId, uatEnvironment }: OneDomainForGoBackCritria) => `${randomId}oo${uatEnvironment}`)
		/** uat aws to mobile */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Mobile && webFunctionId == 'gd' && !enableDesktopOrMobileToOnPremise,
			({ randomId, uatEnvironment }: OneDomainForGoBackCritria) => `${randomId}om${uatEnvironment}`)	
		/** sit local to desktop */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Desktop && webFunctionId == 'gs',
			({ randomId, uatEnvironment }: OneDomainForGoBackCritria) => `${randomId}xo${uatEnvironment}`)
		/** sit local to mobile */
		.on(({ switchFrom }: OneDomainForGoBackCritria) => switchFrom == SwitchFromEnum.Mobile && webFunctionId == 'gs',
			({ randomId, uatEnvironment }: OneDomainForGoBackCritria) => `${randomId}ms${uatEnvironment}`)
		.otherwise(() => 'k957um419');

	url.host = `${convertedSubdomain}.${mainDomain}`;

	if (hasToken) {
		url.pathname = matchEquals(switchFrom)
			.on(SwitchFromEnum.Desktop, () => 'DepositProcessLogin')
			.on(SwitchFromEnum.Mobile, () => 'Deposit_ProcessLogin.aspx')
			.otherwise(() => '');
	} else {
		url.pathname = matchEquals(switchFrom)
			.on(SwitchFromEnum.Desktop, () => 'NewIndex')
			.on(SwitchFromEnum.Mobile, () => 'bfindex')
			.otherwise(() => '');
	}
	// deleteSearchParamsInsensitive(['webskintype', 'skinmode', 'skin'], url.searchParams);
	return url.toString();
}

export const getEnvironmentFromOneDomain = (): AppEnvironment => {
	const { hostname } = location;

	const firstDotIndex = hostname.indexOf('.');

	if (firstDotIndex > -1) {
		const oneDomain = hostname.slice(0, firstDotIndex);
		const [, webFunctionId] = extractOneDomain(oneDomain);

		switch (webFunctionId) {
			case 'gr':
			case 'gc':
			case 'gk':
			case 'gp':
			case 'gl':
			case 'go':
				return 'PROD';
			case 'gu':
			case 'gd':
			case 'gt':
				return 'UAT';
			default:
				return 'UAT';
		}
	} else {
		return 'UAT';
	}
}

export const OneDomainService = {
	getOneDomainForApi,
	getOneDomainLoginUrl,
	getEnvironmentFromOneDomain
}

