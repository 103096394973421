'use client';

import { useAccountInfo } from '@/hooks/query/account';
import { profilePanelService } from '@/services/profile-panel.service';
import { switchViewService } from '@/services/swtich-view.service';
import { useSiteStore } from '@/store/site';
import { useAppearanceStore } from '@/store/appearance';
import { useBetslipStore } from '@/store/betslip';
import { OddsPageLayout, ViewEnum } from '@/types';
import { MaybeMatch, matchEquals } from '@nf/utils-common/fpw/monadic';
import { useLocale } from 'next-intl';
import { useRouter } from '@/navigation';
import { useEffect, useRef } from 'react';

export const AppearanceProvider = () => {
	const { user } = useAccountInfo();
	
	const hasInitialed = useRef<boolean>(false);
	const router = useRouter();
	const locale = useLocale();

	const [
		setIsParlayMode,
		resetAllFilter
	] = useSiteStore(state => [
		state.setIsParlayMode,
		state.resetAllFilter
	]);
	const [
		removeAllTicket,
		setBetslipTab
	] = useBetslipStore(state => [
		state.removeAllTicket,
		state.setBetslipTab
	]);
	const [
		oddsPageLayout,
		setOddsPageLayout,
		view,
		setViewBySkinMode
	] = useAppearanceStore(state => [
		state.oddsPageLayout,
		state.setOddsPageLayout,
		state.view,
		state.setViewBySkinMode
	]);

	// initial layout from account setting
	useEffect(() => {
		if (hasInitialed.current || !user?.MobileVersion) return;
		const initLayout: OddsPageLayout = matchEquals(user?.MobileVersion)
			.on(0, () => 'card')
			.on(1, () => 'list')
			.otherwise(() => 'card');
		MaybeMatch(oddsPageLayout !== initLayout)
			.fold(
				() => { },
				() => setOddsPageLayout(initLayout)
			);
		hasInitialed.current = true;
	}, [user?.MobileVersion, oddsPageLayout, setOddsPageLayout])

	useEffect(() => {
		document.documentElement.setAttribute('data-layout', oddsPageLayout);
	}, [oddsPageLayout])

	useEffect(() => {
		document.documentElement.setAttribute('data-view', ViewEnum[view]);
	}, [view])

	useEffect(() => {
		const subscription = switchViewService.onSwitchView.subscribe(params => {
			router.push(params.href, { locale });
			setViewBySkinMode(params.view);
			setIsParlayMode(false);
			setBetslipTab('single');
			removeAllTicket();
			resetAllFilter();
			profilePanelService.displayProfilePanel(false);
		});

		return () => {
			subscription.unsubscribe();
		}
	}, [])

	return null;
}